import Clickable from '../Clickable';
import InputGroup from '../InputGroup';
import Typography from '../Typography';
import classNames from '/utils/class-names';
import { subscribeEmail } from '/services/email-list';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { useRef, useState } from 'react';

const FooterEmailSignupForm = () => {
	const [email, setEmail] = useState('');
	const [formState, setFormState] = useState({});
	const inputRef = useRef(null);
	const { executeRecaptcha } = useGoogleReCaptcha();

	const submitForm = async (event) => {
		event.preventDefault();

		const response = await subscribeEmail({
			email,
			listKeys: ['footer'],
			executeRecaptcha,
			recaptchaKey: 'footer',
			successMessage: 'Horray you are subscribed!',
		});

		setFormState(response);
	};

	return (
		<div>
			<form action="#" onSubmit={submitForm}>
				<div className="flex justify-center">
					<InputGroup
						ref={inputRef}
						type="text"
						className="text-gray-600 border rounded-l-full border-gray-500 mb-4 placeholder-gray-600 px-2"
						adaTitle="Email"
						placeholder="email@example.com"
						value={email}
						onChange={(val) => {
							setEmail(val);
						}}
					/>
					<Clickable
						buttonType="submit"
						className="rounded-r-full mb-4 bg-primary !px-3 whitespace-nowrap"
						variant="filled">
						Notify Me
					</Clickable>
				</div>
				{formState && formState.message && (
					<div className="flex justify-center mb-4">
						<Typography
							className={classNames(
								'bg-white italic px-2 py-1 rounded-full',
								formState.status === 'success' ? 'text-green-700' : 'text-primary',
							)}>
							{formState.message}
						</Typography>
					</div>
				)}
			</form>
			<div className="flex flex-col items-center">
				<Typography className="text-center" component="div" variant="subtitle-lg">
					Emails suck. Ours don&apos;t.
				</Typography>
				<Typography className="italic mb-4 text-center" component="p" variant="body">
					By clicking that button, you&apos;ll:
				</Typography>
				<div className="flex gap-2 items-center justify-start">
					<Typography
						className="bg-primary flex h-8 items-center justify-center pr-1 rounded-full text-center w-8"
						component="span"
						variant="subhead-md">
						1
					</Typography>
					<Typography className="text-center" component="span" variant="body">
						Confirm you&apos;re a genius and
					</Typography>
				</div>
				<div className="flex gap-2 items-start md:items-center justify-start my-4">
					<Typography
						className="bg-primary flex h-8 items-center justify-center pr-1 rounded-full shrink-0 text-center w-8"
						component="span"
						variant="subhead-md">
						2
					</Typography>
					<Typography className="mb-8 text-center lg:mb-0" component="span" variant="body">
						Start getting our award-winning product updates.
					</Typography>
				</div>
			</div>
		</div>
	);
};

export default FooterEmailSignupForm;
