import Clickable from '/components/Clickable';
import FooterEmailSignupForm from '/components/footer/FooterEmailSignupForm';
import Icon from '/components/IconUpdated';
import Image from '/components/Image';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';

const FooterLink = ({ config }) => {
	if (config.type === 'external') {
		return (
			<Clickable linkToExternal={config.href} target="_blank">
				<Typography className="mb-1 text-white" component="div" variant="body">
					{config.title}
				</Typography>
			</Clickable>
		);
	}

	return (
		<Clickable linkToInternal={config.href}>
			<Typography className="mb-1 text-white" component="div" variant="body">
				{config.title}
			</Typography>
		</Clickable>
	);
};

const Footer = () => {
	const linksLeft = [
		{ href: '/pages/pinnacle-of-fashion', title: 'Mission', type: 'internal' },
		{ href: '/pages/shinesty-team', title: 'Team', type: 'internal' },
		{ href: '/pages/sign-twirling', title: 'Careers', type: 'internal' },
		{ href: '/pages/termsofservice', title: 'Terms', type: 'internal' },
		{ href: '/pages/privacy', title: 'Privacy Policy', type: 'internal' },
		{ href: '/pages/website-accessibility', title: 'Accessibility', type: 'internal' },
		{ href: '/blogs/news', title: 'Blog', type: 'internal' },
		{ href: '/press-releases', title: 'Press Releases', type: 'internal' },
		{ href: '/pages/public-relations', title: 'In The News', type: 'internal' },
		{ href: 'https://www.shinestyforbusiness.com', title: 'Custom Orders', type: 'external' },
		{ href: '/pages/ccpa', title: 'Do not sell my personal information', type: 'internal' },
	];

	const linksRight = [
		{ href: 'https://shinestyfaq.gorgias.help/en-US', title: 'Help', type: 'external' },
		{ href: '/pages/returns-and-exchanges', title: 'Exchanges & Returns', type: 'internal' },
		{
			href: 'https://contact.gorgias.help/forms/6kjd4p3y',
			title: 'Contact Us',
			type: 'external',
		},
		{ href: 'https://account.shinesty.com/account', title: 'Account Login', type: 'internal' },
		{ href: '/refer', title: 'Free $$$', type: 'internal' },
		{ href: '/retail', title: 'Become a Retail Partner', type: 'internal' },
		{ href: '/student-discount', title: 'Student Discount', type: 'internal' },
		{
			href: 'https://shinesty.trysaral.com',
			title: 'Influencers',
			type: 'external',
		},
	];

	return (
		<footer className="bg-neutral-800 mt-auto pb-24 pt-12 px-12 text-slate-50 lg:py-12">
			<div className="grid sm:divide-gray-800 sm:grid-cols-1 lg:divide-gray-500 lg:divide-x lg:grid-cols-2">
				<FooterEmailSignupForm />
				<div className="grid grid-cols-2 lg:pl-16">
					<div className="mx-auto">
						{linksLeft.map((link, i) => {
							return <FooterLink config={link} key={i} />;
						})}
						<div className="flex mt-4">
							<div className="h-6 w-6">
								<Clickable linkToExternal="https://www.facebook.com/Shinesty" target="_blank">
									<Icon name="facebook" width="24px" height="24px" />
								</Clickable>
							</div>
							<div className="h-6 mx-4 w-6">
								<Clickable
									linkToExternal="https://www.instagram.com/shinestythreads/"
									target="_blank">
									<Icon name="instagram" width="24px" height="24px" />
								</Clickable>
							</div>
							<div className="h-6 w-6">
								<Clickable linkToExternal="https://twitter.com/shinesty" target="_blank">
									<Icon name="twitter" width="24px" height="24px" />
								</Clickable>
							</div>
							<div className="h-6 w-6 ml-3">
								<Clickable
									linkToExternal="https://www.tiktok.com/@shinestythreads?lang=en"
									target="_blank">
									<Image
										src="https://cdn.shinesty.com/2023-05-26/tiktok-icon-white.svg"
										alt="Tiktok logo mark"
										height={24}
										width={24}
										objectFit="contain"
									/>
								</Clickable>
							</div>
						</div>
					</div>
					<div>
						{linksRight.map((link, i) => {
							return <FooterLink config={link} key={i} />;
						})}
					</div>
				</div>
			</div>

			<div className="mt-8 text-center">
				<Typography className="mb-3" component="div" variant="body">
					If you are using a screen reader to access our website and having difficulties, please
					contact us{' '}
					<Clickable linkToExternal="https://contact.gorgias.help/forms/6kjd4p3y">
						<Typography component="div" variant="body" className="hover:text-primary">
							here.
						</Typography>
					</Clickable>
					<div>
						This site is protected by reCAPTCHA and the Google
						<div>
							<Clickable
								linkToExternal="https://policies.google.com/privacy"
								className="hover:text-primary">
								Privacy Policy{' '}
							</Clickable>{' '}
							and
							<Clickable
								linkToExternal="https://policies.google.com/terms"
								className="hover:text-primary">
								{' '}
								Terms of Service
							</Clickable>{' '}
							apply.
						</div>
					</div>
				</Typography>
			</div>
		</footer>
	);
};

FooterLink.propTypes = {
	config: PropTypes.object,
};

export default Footer;
